'use client';

import '../../initFirebase';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { usePathname } from 'src/i18n/routing';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import elasticlunr from 'elasticlunr';
import 'normalize.css';

import {
  ContentContext,
  CurrentUserContext,
  CurrentUserContextType,
} from '../../contexts';
import { Content, Item } from '../../types';
import {
  GlobalSearchContext,
  createEmptyIndex,
  indexWithoutBlocking,
} from '../../search';
import { getAnalytics } from 'firebase/analytics';
import { fetchContent } from 'src/lib/firebase/dataAccessClient';

/**
 * On navigation, scrolls the window to the top.
 */
function ScrollToTop() {
  const pathname = usePathname();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}

function Providers({ children }: { children: React.ReactNode }) {
  const [globalSearchIndex, setGlobalSearchIndex] =
    useState<elasticlunr.Index<Item> | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(
    () => getAuth().currentUser,
  );
  const [authLoaded, setAuthLoaded] = useState<boolean>(false);
  const [content, setContent] = useState<Content | null>(null);

  // Memoize user context to prevent unnecessary re-renders, including #42
  const userContext = useMemo<CurrentUserContextType>(
    () => ({
      user: currentUser,
      authLoaded,
    }),
    [currentUser, authLoaded],
  );

  useEffect(() => {
    fetchContent().then((fetchedContent) => {
      setContent(fetchedContent);
    });
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      setCurrentUser(user);
      setAuthLoaded(true);
    });

    return unsubscribe;
  }, []);

  const startIndexing = useCallback((docs: Array<Item>) => {
    const newSearchIndex = createEmptyIndex();
    setGlobalSearchIndex(newSearchIndex);
    indexWithoutBlocking(docs, newSearchIndex, { indexing: true }, () => {
      // Clone the index to force an update to any results currently showing:
      setGlobalSearchIndex(elasticlunr.Index.load(newSearchIndex.toJSON()));
    });
  }, []);

  useEffect(() => {
    if (content && content.docs) {
      startIndexing(content.docs);
    }
  }, [startIndexing, content]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // Start analytics: We can't do this in initFirebase since window is not defined there yet
      getAnalytics();
    }
  }, []);

  return (
    <CurrentUserContext.Provider value={userContext}>
      <ContentContext.Provider value={content}>
        <GlobalSearchContext.Provider value={globalSearchIndex}>
          <ScrollToTop />
          {children}
        </GlobalSearchContext.Provider>
      </ContentContext.Provider>
    </CurrentUserContext.Provider>
  );
}

export default Providers;
