import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/production/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Indie_Flower\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-indie-flower\"}],\"variableName\":\"indieFlower\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/lib/registry.tsx");
