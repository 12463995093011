import {
  getFirestore,
  getDocs,
  query,
  collection,
  where,
} from 'firebase/firestore';
import { Item, Folder, Content } from 'src/types';
import { parseTimestamp } from 'src/utils';

function sortByOrder(a: { order: number }, b: { order: number }): number {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
}

export async function fetchItems<T extends Item | Folder>(
  type: 'folders' | 'docs',
): Promise<T[]> {
  const db = getFirestore();
  const q = query(collection(db, type), where('visibility', '==', 'public'));

  try {
    const querySnapshot = await getDocs(q);
    const items: T[] = [];
    let index = 0;
    querySnapshot.forEach((item) => {
      const data = item.data() as T;
      const order = typeof data.order === 'number' ? data.order : index;
      items.push({
        ...data,
        id: item.id,
        updatedAt: parseTimestamp(data.updatedAt),
        createdAt: parseTimestamp(data.createdAt),
        order,
      });
      index += 1;
    });

    const sortedItems: T[] = items.sort(sortByOrder);

    return sortedItems;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export const itemsById = <T extends Item | Folder>(
  items: Array<T>,
): {
  [key: string]: T;
} =>
  items.reduce(
    (map, item) => {
      map[item.id] = item;
      return map;
    },
    {} as {
      [key: string]: T;
    },
  );

function isInFolderWithId(item: Item, folderId: string): boolean {
  return (
    item.folder === folderId || // Primary folder
    // Or secondary folders:
    Boolean(item.folders && item.folders[folderId])
  );
}

export function filterPublicItems(items: Item[], folders: Folder[]): Item[] {
  // Filter out docs which do not have a primary folder set or are not in a public folder
  return items.filter(
    (i) => i.folder && folders.some((f) => isInFolderWithId(i, f.id)),
  );
}

/**
 * Returns the folder's language. If none is found, it checks the ancestors' language until one is found.
 * If the folder nor its ancestors have a lang defined, it is in English.
 *
 * NOTE: Checking folder.lang should be enough, but checking ancestors as well is a safeguard.
 */
export const getFolderLang = (
  folder: Folder | undefined,
  foldersById: { [id: string]: Folder },
): string => {
  if (folder && folder.lang) {
    return folder.lang;
  }
  if (folder && folder.parent) {
    const parentFolder = foldersById[folder.parent];
    if (parentFolder) {
      return getFolderLang(parentFolder, foldersById);
    }
  }
  return 'en';
};

export const fetchContent = async (): Promise<Content> => {
  const docsPromise = fetch('https://pvapp.b-cdn.net/docs.json').then((res) =>
    res.json(),
  );
  const foldersPromise = fetch('https://pvapp.b-cdn.net/folders.json').then(
    (res) => res.json(),
  );

  const [folders, docs] = await Promise.all([foldersPromise, docsPromise]);

  return {
    folders,
    docs,
    docsById: itemsById(docs),
    foldersById: itemsById(folders),
  };
};
